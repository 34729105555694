.FloatingObject {
    z-index: 1000;
    img {
        width: 150px;
        height: auto;
        animation: float 4s infinite 
    }

    @keyframes float {
        0% {
            transform: translatey(0px) rotateZ(-1deg);
        }
        50% {
            transform: translatey(-3px) rotateZ(1deg);
        }
        100% {
            transform: translatey(0px) rotateZ(-1deg);
        }
    }

    @media only screen and (max-width: 600px) {
        img {
            width: 80px;
            height: auto;;
        }
    }
}