$bg-color: white;
.EveryGardenView {
  overflow: hidden;

  &.hide {
    opacity: 0;
  }
  &.custom-cursor {
    cursor: url("../../assets/snail_small.png") 22 22, default;
    .animated-character {
      display: none;
    }
  }

  .mouse-screen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1100;
  }

  .description-box {
    background: white;

    padding: 1rem;

    position: fixed;
    top: 1rem;
    left: 2rem;
    width: 40%;
    text-align: left;
    opacity: 1;
    transition: all 0.3s ease-in-out;
    z-index: 2000;
    font-size: 0.9rem;
    &.hide {
      opacity: 0;
    }
    .page-title {
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }

    .sortby {
      margin-right: 2rem;
      margin-top: 0.5rem;
      // background: rgb(226, 226, 226);
      // padding: 0.75rem 0;
      // height: 50px;
      // display: flex;
      justify-content: center;
      align-items: center;
      .gray {
        color: gray;
        margin-right: 0.25rem;
      }
      .sortby-text {
        color: gray;
        margin-right: 0.5rem;
      }
      .sortby-options {
        display: flex;
        // background: yellow;
        .option {
          color: gray;
          cursor: pointer;
          &.active {
            text-decoration: underline;
            color: black;
          }
        }
      }
    }

    .actions {
      display: flex;
      margin-top: 1rem;
    }
    .button {
      background: blue;
      width: 200px;
      height: 40px;
      padding: 0.75rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      text-align: center;
      cursor: pointer;
      // transform: scale(.9);
      transition: transform 0.5s cubic-bezier(0.76, 0, 0.24, 1);
      &:hover {
        // transform: scale(1);
      }
    }

    .search-garden {
      width: 300px;
      height: 50px;
      margin-right: 2rem;
      input {
        height: 40px;
        margin: 0;
      }
    }
  }

  .landing-img {
    transition: all 0.7s ease-in-out;
    opacity: 1;

    &.hide {
      opacity: 0;
    }

    &.remove {
      display: none;
    }
    img {
      height: 100%;
    }
  }

  .CameraView {
    position: absolute;
    z-index: 2000;
  }

  .every-garden-wrapper {
    opacity: 1;
    transition: all 0.3s ease-in-out;
    &.hide {
      opacity: 0;
    }
    pointer-events: none;
    &.big {
      pointer-events: unset;
      .every-garden {
        .unique-garden {
          // flex: 0 0 7%;
          // margin: 1rem 0.6666666%;
        }
      }
    }
  }

  .every-garden {
    // transform: rotateX(60deg) rotateY(0deg) rotateZ(-45deg) translateX(-37rem)
    //   scale(1.5) translateY(-280px);
    // transform-origin: top;
    position: absolute;
    margin: auto;
    width: 100%;
    padding: 5rem 1rem;
    display: flex;
    flex-wrap: wrap;
    margin-top: 7rem;
    transition: all .7s ease-in-out;
    
    .garden-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .unique-garden:after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }

    .unique-garden {
      position: relative;
      background: white;
      position: relative;
      // width: 50%;
      color: white;
      padding: 0.5rem;
      position: relative;
      transition: all .3s ease-in-out;
      flex: 0 0 22%;
      margin: 2rem 1.5%;
      cursor: pointer;

      @media only screen and (max-width: 1400px) {
        flex: 0 0 29%;
        margin: 2rem 2.166%;
      }

      @media only screen and (max-width: 1000px) {
        flex: 0 0 43%;
        margin: 2rem 3.5%;
      }

      @media only screen and (max-width: 640px) {
        flex: 0 0 96%;
        margin: 2rem 2%;
      }


      .garden-title {
        // background: blue;
        color: white;
        margin: auto;
        font-size: 2.5rem;
        max-width: 20rem;
        text-align: left;
        padding: 0.5rem;
        position: absolute;
        width: 100%;
        height: 100%;
      }
      .garden-info {
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 100;
        width: 100%;
        height: 100%;
        background: rgb(175, 160, 132);
        box-shadow: -8px 8px rgb(124 99 52);

        .enter-garden {
          display: none;
        }
        &:hover {
          .enter-garden {
            position: absolute;
            top: 35%;
            width: 50%;
            left: 25%;
            display: block;
            padding: 0.5rem;
            background: coral;
            color: white;
            cursor: pointer;
            font-size: 1.5rem;
          }
        }
      }
      .thumbnail {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        object-fit: cover;
      }

      .CanvasView {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
        width: 100%;
        overflow: hidden;
      }
    }
  }

  .animated-character {
    position: fixed;
    position: fixed;
    z-index: 1000;
    left: 54%;
    top: 30%;
    margin: auto;
    transform: rotate(61deg) scale(0.7);
    img {
      width: 150px;
      height: auto;
      position: absolute;
      top: 10rem;
      left: 50%;
    }
  }

  @media only screen and (max-width: 600px) {
    background: $bg-color;
    &.custom-cursor {
      .animated-character {
        display: block;
      }
    }
    .page-description {
      .page-title {
        font-size: 1rem;
      }
      width: 200px;
      font-size: 0.8rem;
    }

    .description-box {
      width: 100%;
      top: 0;
      left: 0;
      .page-body {
        font-size: 0.9rem;
        .page-title {
          font-size: 1.2rem;
        }
      }

      .actions {
        .search-garden {
          width: 50%;
          margin-right: 0.5rem;
        }
        input {
          height: 2.3rem;
          // width: 50%;
        }
        .button {
          height: 2.3rem;
          width: 50%;
        }
      }
    }

    .animated-character {
      padding: 1rem;
      position: fixed;
      bottom: 2rem;
      left: 2rem;
      text-align: left;
      max-width: 400px;
      opacity: 1;
      transition: all 0.3s ease-in-out;
      z-index: 2100;
      font-size: 0.9rem;
    }
    .every-garden {
      margin-top: 5rem;
      .unique-garden {
        width: 100%;
        min-width: 75vw !important;
        max-width: 80rem !important;
      }
    }
    .garden {
      width: unset;
      .heaven {
        min-width: 300px;
      }
      .reality {
        min-width: 300px;
      }
      .hell {
        min-width: 300px;
      }
    }
  }
}
