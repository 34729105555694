.TopicView {
    .page-description {
        background: white;
        padding: 1rem;
        position: fixed;
        top: 2rem;
        left: 2rem;
        text-align: left;
        max-width: 465px;
        opacity: 1;
        transition: all .3s ease-in-out;
        z-index: 2000;
        pointer-events: all;
        transform: scale(1);
        &.hide {
            opacity: 0;
            transform: scale(0);
            pointer-events: none;
        }
        .page-title {            
            font-size: 2rem;
            margin-bottom: 1rem;
        }
        .button {
            background: blue;
            padding: .75rem;
            color: white;
            margin-top: 2rem;
            text-align: center;
            cursor: pointer;
        }

        p { margin: 1rem 0; }
        p:last-child { margin: 1rem 0 0; }
        p:first-child { margin: 0 0 1rem; }

    }

    .timer {
        position: absolute;
        left: 2rem;
        bottom: 2rem;
        padding: 10px 10px;
        z-index: 2000;
        background-color: #FFF;
    }
    
    .landing-img {
        transition: all .7s ease-in-out;
        opacity: 1;
        
        &.hide {
            opacity: 0;
        }

        &.remove {
            display: none;
        }
        img {
                height: 100%;
        }
    }

    .CameraView {
        position: absolute;
        z-index: 2000;
    }

    .garden {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        opacity: 0;
        transition: all .7s ease-in-out;
        &.show {
            opacity: 1;
        }
        .heaven {
            position: relative;
            top: 0;
            width: 33.33333333%;
            .CanvasView {
                width: 100%;
            }
        }
        .reality {
            position: relative;
            top: 0;
            width: 33.33333333%;
            .CanvasView {
                width: 100%;
            }
        }
        .hell {
            position: relative;
            top: 0;
            width: 33.33333333%;
            .CanvasView {
                width: 100%;
            }
        }
    }

    @media only screen and (max-width: 600px) {
        .page-description {
            width: 280px
        }
        .garden {
            width: unset;
            .heaven {
                min-width: 300px;
            }
            .reality{
                min-width: 300px;
            }
            .hell {
                min-width: 300px;
            }
        }
      }
}