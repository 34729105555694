.AboutButton {
    position: fixed;
    display: block;
    background: #fff;
    padding: .5rem;
    z-index: 1000;
    top: 3rem;
    right: 2rem;
    transition: all .5s cubic-bezier(0.76, 0, 0.24, 1);

  .button-about {
    padding: 12px 16px;
    display: inline-block;
    background: blue;
    color: #fff !important;
    font-size: 1rem;
    text-decoration: none;
    transform: scale(1);
    transition: transform .5s cubic-bezier(0.76, 0, 0.24, 1);
    cursor: pointer;
    &:hover {
        transform: scale(1.1);
    }
  }

  .Popup {
      padding: 1rem;
    .title {
      color: black;
      font-size: 1.2rem;
      margin-bottom: 1rem;
    }
    .description {
      color: black;
      text-align: left;
      margin-bottom: 2rem;

      a {
        font-weight: bold;
        cursor: pointer;
      }

      p {
        margin: 0;

        strong {
          margin: 30px 0 10px;
          display: inline-block;
        }

        &:first-child strong {
          margin: 0 0 10px;
        }
      }
    }
    
  }

  @media only screen and (max-width: 600px) {
    display: none;
  }
}
