.topicForm {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: background-color, background-image 0.3s ease-in-out;
  background: #ddd;
  background-size: cover;
  overflow: hidden;
  overflow-y: scroll;
  &.scroll {
    overflow: scroll;
  }

  .see-about-mobile {
    display: none;
  }
  .EveryGardenView {
    .every-garden-wrapper {
      position: absolute;
      z-index: 50;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      .every-garden {
        transform: none;
      }
      .unique-garden {
        .garden-title {
          display: none;
        }
      }
    }
  }

  .see-everygarden {
    z-index: 100;
    max-width: 420px;
    width: 92%;
  }

  h1,
  h4 {
    font-weight: normal;
  }

  h4 {
    color: #fff;
    background-color: #000;
    padding: 1rem;
  }

  .outer-form {
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2rem;
    margin: 1rem 0 1.5rem;
    max-width: 420px;
    width: 92%;
    z-index: 100;
  }

  .uploader {
    color: #777;
    font-size: 90%;
    text-decoration: underline;
    margin-bottom: 0.5rem;
    &:hover {
      text-decoration: none;
    }
  }

  .progress {
    color: rgb(170, 170, 170);
    font-size: 80%;
    margin-top: .25rem;
  }

  .bg-actions {
    display: flex;
    justify-content: space-between;
  }
  .bg-button {
    border:1px solid rgb(175, 175, 175);
    background: white;
    color: rgb(175, 175, 175);
    padding: .35rem .5rem;
    transition: .2s ease-in-out;
    cursor: pointer;
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &:hover {
      background: rgb(175, 175, 175);
      color: white;
    }
  }
  form {
    width: 100%;
    text-align: left;

    label {
      display: block;
      margin: 0;
      cursor: pointer;

      &.private {
        margin-top: 1rem;
      }

      &.background,
      &.sonic {
        margin-top: 1.4rem;
      }

      p {
        line-height: 1.9rem;
        margin: 0;
        padding: 0;
        display: inline-block;
      }

      .background-new {
        margin-top: -0.5rem;
        margin-bottom: 0.5rem;
      }

      a,
      .background-new {
        color: #777;
        font-size: 90%;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }

    span {
      display: block;
      line-height: 1.4rem;
      margin: -1rem 0 0.4rem;
      background-color: #f8f8f8;
      padding: 4px 12px;
      color: rgb(254, 26, 26);
      font-size: 0.8rem;

      a {
        color: rgb(254, 26, 26);
        font-size: 100%;
      }
    }

    span.short {
      margin: -2px 0 0.4rem;
    }

    input {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      font-family: "DM Mono", monospace;
    }

    input:focus {
      outline-style: none;
      box-shadow: none;
      border-color: transparent;
    }

    input[type="text"],
    input[type="number"],
    input[type="submit"] {
      width: 100%;
      padding: 12px;
      margin: 2px 0;
      border: none;
      background: #eee;
      &:focus {
        background: #ddd;
      }
    }

    input[type="checkbox"] {
      margin: 0.2rem 0 0.4rem;
      float: right;
      outline: 0;
      display: block;
      width: 3.6rem;
      height: 1.4rem;
      position: relative;
      cursor: pointer;
      user-select: none;
      // padding: 2px;
      transition: all 0.2s ease;
      background: #ddd;
      border: 2px solid #ddd;
      // border-radius: 2em;
      &:after,
      :before {
        position: relative;
        display: block;
        content: "";
        width: 50%;
        height: 100%;
      }
      &:after {
        transition: all 0.2s ease;
        background: #fff;
        content: "";
        // border-radius: 1em;
        left: 0;
      }
      &::selection {
        background: none;
      }
      &:checked {
        border: 2px solid #000;
        background: #000;
        &:after {
          left: 50%;
          background: #fff;
        }
      }
    }

    input[type="submit"] {
      cursor: pointer;
      -webkit-appearance: none;
      border: none;
      border-radius: 0;
      color: #fff;
      background-color: blue;
      font-size: 1rem;
      transform: scale(1);
      transition: transform 0.5s cubic-bezier(0.76, 0, 0.24, 1);
      &:focus {
        -webkit-appearance: none;
        border: 0;
      }
      &:hover {
        transform: scale(1.1);
      }
    }

    input[type="text"].active,
    input[type="number"].active,
    .active {
      opacity: 1;
      transition: all 0.3s ease-in-out;
      height: 2.8rem;
      padding: 12px;
      margin-bottom: 1rem;
    }

    input[type="text"].hidden,
    input[type="number"].hidden,
    .hidden,
    .hidden p {
      opacity: 0;
      transition: all 0.3s ease-in-out;
      pointer-events: none;
      height: 0rem;
      padding: 0;
      margin: 0;

      input[type="checkbox"] {
        height: 0;
        padding: 0;
        margin: 0;
      }
    }

    .resetable.active {
      height: 2rem;
      padding: 0;
    }
  }

  .link-everygarden {
    max-width: 420px;
    width: 92%;
    margin: 2rem 0;
    color: white;

    .go-to {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  @media only screen and (max-width: 600px) {
    justify-content: start;

    .see-everygarden {
      margin-top: 3rem;
    }

    .actions {
      font-size: 1rem;
    }

    .see-about-mobile {
      display: block;
      text-decoration: underline;
      cursor: pointer;
      transform: scale(1);
      transition: .3s all ease-in-out;
      background: white;
      &:hover {
        transform: scale(1.2);
      }
    }

    .button {
      width: 92%;
      margin: 0;
    }
  }
}
