@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.CanvasView {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  text-align: left;
  padding: 1rem;
  width: 100%;
  height: 100%;

  &.party {
    animation: gradient 1s ease infinite;
  }

  background: rgb(162, 209, 251);
  background: linear-gradient(
    -180deg,
    rgba(162, 209, 251, 1) 0%,
    rgba(168, 199, 212, 1) 20%,
    rgba(157, 205, 154, 1) 49%,
    rgba(123, 172, 99, 1) 82%,
    rgba(193, 172, 130, 1) 100%
  );

  &.SonicCanvas {
    overflow: hidden;
  }

  .sequencer-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .sonic-controls {
    top: 120px;
    right: 40px;
    z-index: 10;
    width: 180px;
    height: 80px;
    label {
      color: white;
    }
  }

  .sequencer-col {
    &.active {
      background: rgba(255, 255, 255, 0.08);
    }
  }

  .canvasItems {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .title {
    font-size: 2rem;
    margin-left: 1rem;
    margin-top: 2rem;
    text-transform: uppercase;
    color: #fafafa;
    mix-blend-mode: difference;
  }

  background: rgb(162, 209, 251);
  background: linear-gradient(
    -180deg,
    rgba(162, 209, 251, 1) 0%,
    rgba(168, 199, 212, 1) 20%,
    rgba(157, 205, 154, 1) 49%,
    rgba(123, 172, 99, 1) 82%,
    rgba(193, 172, 130, 1) 100%
  );

  &.heaven {
    background: rgb(162, 209, 251);
    background: linear-gradient(
      -180deg,
      rgba(162, 209, 251, 1) 0%,
      rgba(168, 199, 212, 1) 20%,
      rgba(176, 184, 143, 1) 48%,
      rgba(123, 172, 99, 1) 76%,
      rgba(193, 172, 130, 1) 100%
    );
  }

  &.hell {
    background: rgb(64, 63, 73);
    background: linear-gradient(
      0deg,
      rgba(64, 63, 73, 1) 0%,
      rgba(68, 43, 16, 1) 18%,
      rgba(73, 73, 89, 1) 52%,
      rgba(64, 44, 0, 1) 65%,
      rgba(176, 152, 99, 1) 100%
    );
  }

  &.reality {
    background: rgb(162, 209, 251);
    background: linear-gradient(
      -180deg,
      rgba(162, 209, 251, 1) 0%,
      rgba(168, 199, 212, 1) 20%,
      rgba(157, 205, 154, 1) 49%,
      rgba(123, 172, 99, 1) 82%,
      rgba(193, 172, 130, 1) 100%
    );
  }

  button {
    position: absolute;
    bottom: 3rem;
    left: 2rem;
  }

  .text,
  img {
    position: absolute;
    z-index: 100;
    margin: 0;
  }

  img:hover {
    filter: drop-shadow(5px 5px 0 white) drop-shadow(-5px -5px 0 white) drop-shadow(-5px 5px 0 white) drop-shadow(5px -5px 0 white);
  }

  .img-entry {
    transition: .3s all ease-in ;
  }
  .img-entry.sonic {
    // width: 250px;
    // height: auto;
    transform: scale(0.3);
    transition: 500ms ease-in-out transform;
    &.active {
      transform: scale(0.5);
    }
  }

  input[type="range"] {
    -webkit-appearance: none;
    margin: 18px 0;
    width: 100%;
    background: transparent;
  }

  input[type="range"]:focus {
    outline: none;
  }

  input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    background: white;
    border-radius: 15px;
    border: 2px solid #010101;
  }

  input[type="range"]::-webkit-slider-thumb {
    border: 1px solid #000000;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -9px;
  }

  input[type="range"]:focus::-webkit-slider-runnable-track {
    /* background: #367ebd; */
  }

  input[type="range"]::-moz-range-track {
    height: 4px;
    cursor: pointer;
    border-radius: 15px;
    background: white;
    border: 5px solid #010101;
  }

  input[type="range"]::-moz-range-thumb {
    border: 5px solid #000000;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
  }

  input[type="range"]::-ms-track {
    height: 5px;
    cursor: pointer;
    -ms-border-radius: 15px;
    border-radius: 15px;
    background: transparent;
    border-color: transparent;
    border-width: 16px 0;
    color: transparent;
  }

  input[type="range"]::-ms-fill-lower {
    // background: linear-gradient(90deg, #80B1FE 0%, #FFB69F 100%);
    border: 2px solid #010101;
    border-radius: 2.6px;
  }

  input[type="range"]::-ms-fill-upper {
    // background: linear-gradient(90deg, #80B1FE 0%, #FFB69F 100%);
    border: 2px solid #010101;
    border-radius: 2.6px;
  }

  input[type="range"]::-ms-thumb {
    border: 5px solid #000000;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
  }

  input[type="range"]:focus::-ms-fill-lower {
    background: transparent;
  }

  input[type="range"]:focus::-ms-fill-upper {
    background: transparent;
  }

  .text-entry,
  img {
    cursor: move;
  }

  .text-link-block {
    // display: flex;
    max-width: 550px;
    width: fit-content;
    background: white;
    font-size: 0.8rem;
    padding: 4px 8px;
    transform: translate(-50%, -50%);
    transform-origin: left top;
    .link {
      cursor: pointer;
      text-decoration: underline;
    }
    
  }

  .react-draggable {
    transition: transform 0.3s ease-out;
  }

  .react-draggable-dragging {
    transition: none;
  }

  .text {
    background-color: #fff;
    color: #000;
    font-size: 0.8rem;
    padding: 4px 8px;
    transform: translate(-50%, -50%);
    transform-origin: left top;
    max-width: 600px;
  }

  .text.dummy {
  }

  .clef-wrapper {
    pointer-events: none;
    z-index: 1;
    top: 0;
    left: 0;
    width: 80px;
    height: 100vh;

    .g-clef,
    .c-clef,
    .f-clef {
      img {
        transform: none;
      }
    }
    .g-clef {
      img {
        width: 20px;
        height: auto;
      }
      width: auto;
      height: 33vh;
    }
    .c-clef {
      img {
        width: 25px;
        height: auto;
      }
      width: auto;
      height: 33vh;
    }
    .f-clef {
      img {
        width: 25px;
        height: auto;
      }
      width: auto;
      height: 33vh;
    }
  }

  [contentEditable="true"]:empty:before {
    content: attr(data-ph);
    color: grey;
    font-style: italic;
  }

  img {
    // width: 240px;
    transform: scale(0.5) translate(-50%, -50%);
    transition: all 0.5s cubic-bezier(0.76, 0, 0.24, 1);
    transform-origin: left top;
    max-width: 50%;
    height: auto;
  }

  .about-hidden {
    transform: scale(0);
  }

  .camera-icon {
    position: absolute;
    bottom: 2rem;
    right: 1.3rem;
    z-index: 999;
    background: #fff;
    border-radius: 50px;
    padding: 0.8rem;
    width: 65px;
    height: 65px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: all 0.5s cubic-bezier(0.76, 0, 0.24, 1);

    &.hidden {
      transform: scale(0);
    }

    &.show {
      transform: scale(1);
    }

    .icon {
      position: absolute;
      opacity: 1;
      transition: all 0.5s cubic-bezier(0.76, 0, 0.24, 1);
    }

    &:hover {
      transform: scale(1.2);
    }

  }

  .qr-icon {
    position: absolute;
    bottom: 7rem;
    right: 1.3rem;
    z-index: 999;
    background: #fff;
    border-radius: 50px;
    padding: 0.8rem;
    width: 65px;
    height: 65px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: all 0.5s cubic-bezier(0.76, 0, 0.24, 1);

    &.hidden {
      transform: scale(0);
    }

    &.show {
      transform: scale(1);
    }

    .qrCode {
      position: relative;
      width: 100%;
      opacity: 1;
      text-align: center;
      font-size: 0.8rem;
      margin: 0;
      padding: 0;
    }

    .qr-text {
      display: none;
    }

    .icon {
      position: absolute;
      opacity: 1;

      &.close {
        top: 10px;
        right: 10px;
      }
    }

    .qrCode,
    .icon {
      transition: all 0.5s cubic-bezier(0.76, 0, 0.24, 1);
    }

    &:hover {
      transform: scale(1.2);
    }

    &.first {
      width: 180px;
      height: 200px;
      border-radius: 0;

      .qrCode {
        padding-top: 0.4rem;
      }

      .qr-text {
        display: inline;
      }
    }

    &.first {
      bottom: 50%;
      right: 50%;
      transform: translate(50%, 50%) scale(2);
    }
  }

  .see-about-mobile {
    display: none;
  }

  @media only screen and (max-width: 600px) {
    .text {
      font-size: 0.6rem;
      padding: 3px 6px;
    }

    .see-about-mobile {
      position: absolute;
      top: 1rem;
      right: 1rem;
      display: block;
      display: flex;
      align-items: center;
      justify-content: center;

      // background: blue;
      color: white;
      text-decoration: underline;
      // width: 60px;
      // height: 60px;
      // border-radius: 50%;
      padding: .5rem;
      z-index: 2000;
      transform: scale(1);
      transition: .3s all ease-in-out;
      &:hover {
        transform: scale(1.2)
      }
    }
  }
}
