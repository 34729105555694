.Popup {
  .image__container {
    width: 100%;
    height: auto;
    img {
      width: 100%;
    }
  }
  .modal__footer {
    display: flex;
    justify-content: flex-end;
    .action-container {
      width: 50%;
      display: flex;
      justify-content: space-between;
      .btn {
        margin-left: 1rem;
      }
      a {
        cursor: pointer;
      }
    }
  }
}

.party-icon {
  position: absolute;
  bottom: 18rem;
  right: 2rem;
  z-index: 1000;
  background: #fff;
  border-radius: 50px;
  padding: 0.8rem;
  width: 50px;
  height: 50px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: all 0.5s cubic-bezier(0.76, 0, 0.24, 1);
  transform: scale(1);
  &.hidden {
    transform: scale(0) !important;
  }

  &.show {
    transform: scale(1);
  }

  &:hover {
    transform: scale(1.2);
  }
}

.play-icon {
  position: absolute;
  bottom: 12rem;
  right: 2rem;
  z-index: 1000;
  background: #fff;
  border-radius: 50px;
  padding: 0.8rem;
  width: 50px;
  height: 50px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: all 0.5s cubic-bezier(0.76, 0, 0.24, 1);
  transform: scale(1);
  &.hidden {
    transform: scale(0) !important;
  }

  &.show {
    transform: scale(1);
  }

  &:hover {
    transform: scale(1.2);
  }
}

.save-icon {
  position: absolute;
  bottom: 7rem;
  right: 2rem;
  z-index: 1000;
  background: #fff;
  border-radius: 50px;
  padding: 0.8rem;
  width: 50px;
  height: 50px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: all 0.5s cubic-bezier(0.76, 0, 0.24, 1);
  transform: scale(1);
  &.hidden {
    transform: scale(0) !important;
  }

  &.show {
    transform: scale(1);
  }

  &:hover {
    transform: scale(1.2);
  }

  @media screen and (max-width: 600px) {
    &.show {
      transform: scale(0);
    }
  }
}

.popup-overlay {
  z-index: 1000 !important;
}
