.inner {
    $red: #FB7593;
    $yellow: #FFD05C;
    $green: #76E19E;
    $progress-step-3-bg: $green;
    $progress-step-2-bg: $yellow;
    $progress-step-1-bg: $red;

    padding: 8px 14px;
    position: relative;

    .progress {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 100%;
        background-color: #FFF;
        transition: background-color 0.3s ease;
        width: 100%;
    }

    .step-1 {
        background-color: $progress-step-1-bg;
    }

    .step-2 {
        background-color: $progress-step-2-bg;
    }

    .step-3 {
        background-color: $progress-step-3-bg;
    }

    span {
        position: relative;
        font-size: 1.6rem;
    }

    .red {
        transition: color 0.3s ease;
        color: $red;
    }
    
    span:not(:last-child):after {
        content: ":"
    }
}