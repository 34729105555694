.DeleteGarden {
    .delete-garden-btn {
        background: red;
        color: white;
        padding: 1rem;
        border: 5px solid white;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
    
        z-index: 2000;;
        top: 6rem;
        left: 2rem;
        transform: scale(1);
        transition: all .3s ease-in-out;
        &:hover {
            transform: scale(1.1)
        }
        // right: 0;
        svg {
            width: 25px;
            height:auto;
        }
    }
    

    .confirmation-popup {
        background: rgba(0, 0, 0, 0.384);
        z-index: 2100;
        position: absolute;
        top: 0;
        left:0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .popup-content {
            padding: 2rem;
            max-width: 30rem;
            background: white;
            color: black;
            .actions {
                margin-top: 1rem;
                display: flex;
            }
        }

    }
}