.NavBar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1500;
    background: yellow;
    color: rgb(27, 27, 27);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.7rem 3rem;
    font-size: .9rem;
    &.hide {
        display: none;
    }

    &.show {
        display: show;
    }

    .close {
        font-size: 1.2rem;
        // padding-right: 1rem;
        cursor: pointer;
        margin-left: 1rem;
    }

    a {
        text-decoration: underline;
        cursor: pointer;
    }

    @media only screen and (max-width: 600px) {
        top: unset;
        bottom: 0;
        padding: .4rem .7rem;

        font-size: .8rem;
    }
}