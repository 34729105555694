.DeleteItemFromGarden {
    background: white;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;

    z-index: 2000;;
    top: 0;
    transform: scale(1);
    transition: all .3s ease-in-out;
    &:hover {
        transform: scale(1.1)
    }
    // right: 0;
    svg {
        width: 25px;
        height:auto;
    }
}