@import url("https://fonts.googleapis.com/css2?family=DM+Mono:wght@400;500&display=swap");
.App {
  text-align: center;
  font-family: "DM Mono", monospace;
}

#bmc-wbtn{
  bottom: 2em;
  left: 1.3rem;
}

#bmc-wbtn + div{
  bottom:6rem;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
a:-webkit-any-link {
  color: unset;
  cursor: unset;
  text-decoration: unset;
}

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: "DM Mono", monospace;
}

input:focus {
  outline-style: none;
  box-shadow: none;
  border-color: transparent;
}

.button {
  background: blue;
  border: 1px solid blue;
  margin-left: 12px;
  color: white;
  padding: 1rem;
  transform: scale(1);
  transition: transform 0.5s cubic-bezier(0.76, 0, 0.24, 1);
  cursor: pointer;
  width: fit-content;
  font-size: .9rem;
  &:hover {
    transform: scale(1.1);
  }

  &.secondary {
    background: #FFF;
    border: 1px solid blue;
    color: blue;
  }
}

input[type="text"],
input[type="number"],
input[type="submit"] {
  width: 100%;
  padding: 12px;
  margin: 2px 0;
  border: none;
  background: #eee;
  &:focus {
    background: #ddd;
  }
}

.button {
  background: blue;
  width: 200px;
  height: 40px;
  padding: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  cursor: pointer;
  // transform: scale(.9);
  transition: transform 0.5s cubic-bezier(0.76, 0, 0.24, 1);
  &:hover {
    // transform: scale(1);
  }
}

.btn {
  &.primary {
    background: blue;
    color: #fff !important;
    border: 1px solid blue;
  }

  &.secondary {
    background: transparent;
    color: blue;
    border: 1px solid blue;
  }

  padding: 12px 16px;
  display: inline-block;
  width: 100%;
  font-size: 1rem;
  text-decoration: none;
  transform: scale(1);
  transition: transform 0.5s cubic-bezier(0.76, 0, 0.24, 1);
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
}
* {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
