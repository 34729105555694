.notification {
    position: fixed;
    bottom: -4rem;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    background: #000;
    color: #FFF;
    padding: .5rem;
    z-index: 1000;
    opacity: 0;
    animation: 8s cubic-bezier(0.76, 0, 0.24, 1) away;
}

@keyframes away {
    0% { bottom: -4rem; opacity: 0; }
    20% { bottom: 3rem; opacity: 1; }
    90% { bottom: 3rem; opacity: 1; }
    100% { bottom: -4rem; opacity: 0; }
  }