$grid-thickness: 1px;
$grid-color: black;
.FooterAbout {
  background: blue;
  color: white;
  position: relative;
  bottom: -10rem;
  left:0;
  width: 100vw;
  height: 10rem;
  margin-top: 10rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  a {
    text-decoration: underline;
    cursor: pointer;
  }
  .made-by {
    margin-top: 1rem;
  }
}