.MediaLinks {
    display: flex;
    .icon-media {
        background: white;
        border-radius: 50%;
        margin: 0 .75rem 1rem;
        padding: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all .3s ease-in-out;
        transform: scale(1);
        &:hover {
            transform: scale(1.2);
        }
        svg {
            width: 24px;
            
        }
    }

    @media only screen and (max-width: 600px) {
        margin-top: 1rem;
    }
}