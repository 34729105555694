$grid-thickness: 1px;
$grid-color: black;
.AboutView {
  position: relative;
  text-align: left;
  margin-top: -1px;
  margin-left: -1px;
  background: white;
  background-image: linear-gradient(
      $grid-color $grid-thickness,
      transparent $grid-thickness
    ),
    linear-gradient(
      90deg,
      $grid-color $grid-thickness,
      transparent $grid-thickness
    );
  background-size: 6.4rem 6.4rem;
  
  .about-actions {
    display: flex;
    width: 100%;
    // justify-content: space-around;
    margin-top: 3rem;
    .button {
      padding:.75rem;
      width: 250px;
      margin-left: 0;
      margin-right: 1rem;
      font-size: 1.2rem;
      height: unset;
    }
  }

  .link {
    background: yellow;
    cursor: pointer;
  }

  ul {
    list-style-position: outside;
  }

  li {
    line-height: 120%;
    margin-bottom: 2rem;
    padding-left: 1em;
  }

  .section {
    min-height: 80vh;
    min-width: 100vw;
    padding: 12% 5%;
    position: relative;

    &.one {
      .title {
        font-size: 9rem;
        font-weight: bold;
        color: blue;
        margin-bottom: 1rem;
      }
      .text {
        font-size: 2.2rem;
        max-width: 55rem;
        color: blue;
        margin-bottom: 1rem;
      }
      .actions {
        // display: flex;
        font-size: 1.5rem;
        color: blue;
        .media-link{
          background: yellow;
          cursor: pointer;
          margin: 0 1rem;
        }
      }
      
      &.right {
        text-align: right;
        color: white !important;
      }
    }

    &.two {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2rem;
      color:  blue;
      .text {
        max-width: 50rem;
      }
    }

    &.three {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      font-size: 2rem;
      .title {
        max-width: 60rem;
        font-size: 5rem;
        font-weight: bold;
        color: blue;
        margin-bottom: 2rem;
      }
      .subtitle {
        max-width: 60rem;
        font-size: 2rem;
        font-weight: thin;
        color: blue;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .section {
      &.one {
        .title {
          font-size: 5rem;
          margin-top: 20%;
        }
      }
      &.two {
        .text {
          font-size: 1.5rem;
        }
      }
      &.three {
        .title {
          font-size: 3rem;
          margin-top: 20%;
        }
        .subtitle {
          font-size: 1.5rem;
        }
      }
    }
  }
}
