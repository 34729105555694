.CameraView {
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    overflow: hidden;
    background: black;

    .camera-content {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all .3s ease-in-out;

        &.show {
            opacity: 1,
        }

        &.hide {
            opacity: 0;
            pointer-events: none;
        }

        video {
            width: auto;
            height: 100vh;
        }
    }

    .img-preview {
        position: absolute;
        img {
            width: 100%;
        }
    }

    .close-button {
        position: absolute;
        top: 5%;
        right: 5%;
        background: blue;
        border: 3px solid blue;
        color: white;
        padding: 1rem;
        z-index: 4000;
        width: 50px;
        height: 50px;
        font-size: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        cursor: pointer;
    }

    .flip-camera {
        display: none;
        position: absolute;
        bottom: 5%;
        right: 5%;
        background: rgba(255, 255, 255, 0.363);
        border-radius: 50px;
        padding: .5rem;
        width: 60px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2100;
        svg {
            width: 45px;
            height: 45px;
        }
        cursor: pointer;
    }
    .version {
        position: absolute;
        top: 10%;
        left: 10%;
        background: white;
        border: 3px solid blue;
        color: blue;
        padding: 1rem;
        z-index: 2100;
        font-size: 1.3rem;
        text-transform: uppercase;
        display: flex;
        flex-direction: column;
    }


    .version-selector {
        position: absolute;
        top: 5%;
        left: 5%;
        z-index: 3000;
        // display: flex;
    
    .version-selector-desktop {
        
        background: white;
        text-align: left;
        color: blue;
        font-size: 1rem;
        // padding: 1rem;
        max-width: 80%;
        
        border: 2px solid blue;
        .title {
            padding: 1rem;
        }
        .versions {
            display: flex;
            justify-content: space-between;
            background: rgb(230, 230, 230);
            
        }
        .version-type {
            padding: .5rem;
            width: 33%;
            display: flex;
            justify-content: center;
            cursor: pointer;
            &.active {
                background: blue;
                color: white;
            }

            &.long {
                justify-content: flex-start;
                padding-left: 1rem;
                width: 100%;
            }
        }
    }

    .qr-code {
        margin-top: 1rem;
        background: white;
        text-align: left;
        color: blue;
        width: 250px;
        z-index: 3000;
        padding: 1rem;
        display: flex;

        
        canvas {
            height: 84px !important;
            width:84px !important;
        }
        .title {
            margin-left: .7rem;
            font-size: .8rem;
        }
    }
    }   

    .image-taken {
        position: absolute;
        top:0;
        bottom:0;
        left:0;
        right:0;
        z-index: 3000;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: none;
        .content {
            width: 400px;
            background: white;
            padding: 1rem;
            color: blue;
            font-size: 1.3rem;
        }
    }
    @media only screen and (max-width: 600px) {
        position: absolute;
        top:0px;
        left:0;
        right:0;

        .qr-code {
            display: none !important;
        }
        .camera-content {
            video {
                width: 100vw;
                height: auto;
            }
        }

        .flip-camera {
            display: unset;
        }

        .version {
            position: absolute;
            top: 5%;
            left: 5%;
            background: white;
            border: 3px solid blue;
            color: blue;
            padding: 1rem;
            z-index: 2100;
            font-size: .5rem;
            text-transform: uppercase;
        }

        &.reality {
            left: 350px;
        }

        &.hell {
            left: 640px;
        }
      }
}